<template>
    <div class="autocomplete-field">
        <p class="primary--text small--text">{{ current.title }}</p>

        <v-autocomplete
            class="field"
            dense
            filled
            solo
            flat
            chips
            :disabled="loading"
            v-model="model"
            :items="current.options"
            item-text="text"
            item-value="value"
            :multiple="current.maximum != 1"
            :placeholder="current.placeholder || current.title"
            :hint="current.hint"
            :type="current.type"
            :required="current.minimum > 0"
            :rules="rules"
            :hide-details="hideDetails"
            :search-input.sync="search"
            @focus="getOptions()"
            :return-object="returnObject"
        >
            <template v-slot:append>
                <v-progress-circular
                    v-if="processing"
                    size="18"
                    width="2"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <template v-if="current.type == 'category'">
                    <div class="d-flex align-center py-3" v-on="on" v-bind="attrs">
                        <div class="d-flex flex-column align-start">
                            <span class="small--text"
                                >{{ item.text }}
                                <span
                                    class="caption py-1 px-2 dark white--text rounded-xl ml-2"
                                    v-if="item.item && item.item.data && item.item.data.type"
                                    >{{ item.item.data.type }}</span
                                ></span
                            >
                        </div>
                    </div>
                </template>

                <template v-if="current.image">
                    <div class="d-flex align-center py-3" v-on="on" v-bind="attrs">
                        <template v-if="hasImage(item)">
                            <v-img
                                class="rounded-lg mr-3"
                                width="100"
                                height="70"
                                :src="$fluro.asset.imageUrl(item.item.data.image)"
                            >
                                <template v-slot:placeholder>
                                    <v-row class="fill-height grey ma-0" align="center" justify="center">
                                        <v-progress-circular
                                            :width="2"
                                            :size="20"
                                            color="dark"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>

                        <div class="d-flex flex-column align-start">
                            <b class="small--text">{{ item.text }}</b>
                            <p class="small--text mb-0" v-if="item.message">{{ item.message }}</p>
                        </div>
                    </div>
                </template>
            </template>

            <template v-slot:selection="{ item, attrs, on }" v-if="current.type == 'category'">
                <div class="d-flex align-center py-3" v-on="on" v-bind="attrs">
                    <div class="d-flex flex-column align-start">
                        <v-chip style="padding-right: 6px" color="primary" elevation="0">
                            <span class="small--text"
                                >{{ item.text }}
                                <span
                                    class="caption py-1 px-2 white primary--text rounded-xl ml-2"
                                    v-if="item.item && item.item.data && item.item.data.type"
                                    >{{ item.item.data.type }}</span
                                ></span
                            >
                        </v-chip>
                    </div>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    name: "autocomplete-field",

    props: {
        value: [String, Object, Number, Array],
        title: String,
        selected: Object,
        disabled: Boolean,
        loading: Boolean,
        disableRules: Boolean,
        hideDetails: Boolean,
        returnObject: Boolean,
    },

    data() {
        return {
            model: this.value,
            current: this.selected,
            search: null,
            error: false,
            processing: false,
            valid: false,
            // rules: Rules
        };
    },

    computed: {
        rules() {
            let model = this.model;
            let current = this.current;

            if (current.minimum >= 1) {
                if ((model && model.length && model.length < current.minimum) || (model && !model.length)) {
                    return [(v) => v.length > current.minimum || `Minimum ${this.getRule(current.minimum)} required`];
                }

                if (current.maximum == 1) {
                    return [(v) => !!v || "This field is required"];
                }
            }

            if (current.maximum >= 1) {
                if (model && _.isArray(model) && model.length && model.length > current.maximum) {
                    return [(v) => v.length < current.maximum || `Maximum ${this.getRule(current.maximum)} required`];
                }
            }

            return [];
        },
    },

    methods: {
        hasImage(item) {
            let image = item && item.item && item.item.data && item.item.data.image;

            return image?._id || image;
        },
        isSelected(value) {
            return this.model.some((item) => item.value === value);
        },
        isString(model) {
            if (typeof model == "string") {
                return true;
            }

            return false;
        },
        getRule(number) {
            if (number != 1) {
                return number + " items are";
            } else {
                return number + " item is";
            }
        },
        async getOptions() {
            if (this.current.options.length > 0) return;
            if (this.processing) return;

            this.processing = true;
            const user = this.$app.user;
            try {
                let criteria = {
                    filter: {
                        operator: "and",
                        filters: this.current.filters,
                    },
                    select: this.current.select || [],
                    includeArchived: false,
                    allDefinitions: true,
                    searchInheritable: false,
                    includeUnmatched: false,
                };

                this.current.options = await this.$fluro.content.filter(this.current.type, criteria).then((results) => {
                    var response = results;

                    response = response
                        .map((item) => {
                            let data = {
                                text: item.title,
                                value: item._id,
                                disabled: false,
                                item,
                            };

                            let purchase = item?.data?.purchase;
                            // let submission = item && item.data && item.data.submission;
                            // let judging = item && item.data && item.data.judging;
                            let today = moment().format();

                            if (purchase) {
                                let startDate = purchase.startDate;
                                let endDate = purchase.endDate;

                                data.disabled = this.current?.allowDisable ? true : false;

                                if (startDate <= today && endDate > today) {
                                    data.disabled = false;
                                } else {
                                    data.message = `Purchase entries on ${moment(startDate).format("Do MMM, YYYY")}`;
                                }
                            }

                            return data;
                        })
                        .sort((a, b) => a.disabled - b.disabled);

                    return response;
                });

                //Realm didnt seem to be filtering so added this
                this.current.options = this.current.options.filter((item) => {
                    return item.item.realms.some((realm) => {
                        if (user.permissionSets["62d0b451f69e5f15bebb6fec"]) {
                            return realm._id === this.$app.liveRealm || realm._id === this.$app.liveSandbox;
                        } else {
                            return realm._id === this.$app.liveRealm;
                        }
                    });
                });
            } catch (err) {
                console.log("ERROR", err);
            }
            this.processing = false;
        },
    },

    watch: {
        model(v) {
            if (v && v.length) {
                let items = v.filter((item) => item);
                this.$emit("input", items);
            } else {
                this.$emit("input", v.item);
            }
        },
        async search(val) {
            await this.getOptions();
        },
    },

    async mounted() {
        if (this.model?.length) {
            await this.getOptions();
        }
    },
};
</script>

<style lang="scss">
.v-autocomplete {
    ::-webkit-scrollbar {
        // width: 5px;
        display: none;
    }
}

.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
    max-height: inherit !important;
}
</style>
