<template>
    <v-dialog v-model="display" persistent content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="complimentary-dialog no-selection dark--text">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <template v-if="model.competition && model.competition._id">
                            <div class="group-item pa-3">
                                <div class="d-flex align-center">
                                    <template v-if="hasImage(model.competition)">
                                        <v-img
                                            class="rounded-lg mr-3 d-block"
                                            width="100"
                                            height="70"
                                            :src="$fluro.asset.imageUrl(model.competition.data.image)"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row class="fill-height grey ma-0" align="center" justify="center">
                                                    <v-progress-circular
                                                        :width="2"
                                                        :size="20"
                                                        color="dark"
                                                        indeterminate
                                                    ></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </template>

                                    <b class="small--text">{{ model.competition.title }}</b>
                                </div>

                                <v-btn color="dark" icon text @click="model.competition = null">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </template>

                        <template v-else>
                            <autocomplete-field
                                :key="display"
                                :selected="competitions"
                                v-model="model.competition"
                                hide-details
                                return-object
                                :loading="loading"
                            ></autocomplete-field>
                        </template>
                    </v-col>

                    <v-col cols="12" v-if="model.competition && model.competition._id">
                        <div class="group-item pa-3 d-flex flex-column full-width">
                            <p class="primary--text small--text text-left full-width">
                                How many entries do you want to create?
                            </p>
                            <v-text-field
                                v-model="model.complimentaryEntries"
                                type="number"
                                class="field full-width"
                                placeholder="Type in a number..."
                                hide-details
                                dense
                                filled
                                solo
                                flat
                                required
                                :rules="[rules.required]"
                            >
                            </v-text-field>

                            <template v-if="isDigital">
                                <hr class="my-6 full-width" />

                                <div class="full-width d-flex align-center justify-space-between">
                                    <v-switch class="ma-0 pa-0" hide-details v-model="model.feedback" inset>
                                        <template v-slot:label>
                                            <span class="small--text">Allow feedback on entries</span>
                                        </template>
                                    </v-switch>
                                </div>
                            </template>

                            <template v-if="isPrint">
                                <hr class="my-6 full-width" />

                                <div class="full-width d-flex align-center justify-space-between">
                                    <v-switch class="ma-0 pa-0" hide-details v-model="model.returnPrint" inset>
                                        <template v-slot:label>
                                            <span class="small--text">Allow prints to be returned</span>
                                        </template>
                                    </v-switch>
                                </div>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <v-card-actions class="actions light pa-3 sticky bottom">
                <v-btn :color="cancelColor" uppercase @click="cancel"
                    ><span class="dark--text">{{ cancelLabel }}</span></v-btn
                >
                <v-btn :disabled="!isValid" :color="actionColor" uppercase @click="submit">{{ actionLabel }}</v-btn>
            </v-card-actions>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Services
import Contact from "@/modules/app-module/contact";

// Components
import AutocompleteField from "@/components/form-field/autocomplete-field.vue";

// Packages
import _ from "lodash";
import moment from "moment";

export default {
    name: "complimentary-dialog",

    components: {
        AutocompleteField,
    },

    props: {
        title: {
            type: String,
            default() {
                return "Complimentary Entries";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            display: false,
            loading: false,
            loadingStripe: false,
            showError: false,
            codeLength: 6,
            stripe: null,
            message: "",
            errorMessage: "",
            model: {},
            realm: "",
            user: null,
            competitions: {
                minimum: 1,
                maximum: 1,
                options: [],
                title: "Select Competition",
                placeholder: "Select Competition",
                type: "competition",
                filters: [
                    {
                        comparator: "in",
                        key: "data.type",
                        values: ["print", "digital"],
                    },
                    {
                        key: "status",
                        comparator: "in",
                        values: ["active"],
                    },
                    {
                        comparator: "dateafter",
                        dataType: "date",
                        key: "data.purchase.endDate",
                        value: moment().format("YYYY-MM-DD"),
                    },
                    {
                        comparator: "dateafter",
                        dataType: "date",
                        key: "data.judging.startDate",
                        value: moment().format("YYYY-MM-DD"),
                    },
                    {
                        comparator: "!=",
                        dataType: "boolean",
                        key: "data.purchase.closed",
                        value: true,
                    },
                    {
                        comparator: "!=",
                        dataType: "boolean",
                        key: "data.submission.closed",
                        value: true,
                    },
                ],
                select: ["title", "data", "data.bundles", "realms"],
                image: true,
            },
            rules: {
                required: (value) => !!value || "This field is required",
            },
        };
    },

    computed: {
        isDigital() {
            let model = this.model;

            if (model.competition) {
                let competition = model.competition;

                if (competition.data && competition.data.type && competition.data.type == "digital") {
                    return true;
                }
            }

            return false;
        },
        isPrint() {
            let model = this.model;

            if (model.competition) {
                let competition = model.competition;

                if (
                    competition.data &&
                    competition.data.type &&
                    competition.data.type == "print" &&
                    competition.data.returnPrice
                ) {
                    return true;
                }
            }

            return false;
        },
        isValid() {
            let model = this.model;

            if (!model.competition || !model.competition._id) return false;

            if (!model.complimentaryEntries || model.complimentaryEntries < 1) return false;

            return true;
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || "An error occured. Try again.";
            return this.message || "";
        },
    },

    methods: {
        hasImage(item) {
            let image = item && item.data && item.data.image;

            return image?._id || image;
        },
        reset() {
            this.user = null;
            this.loading = false;
            this.showError = false;
            this.errorMessage = "";
            this.model = {};
        },
        async open(model, message) {
            this.message = message;
            this.display = true;

            await this.init(model);
        },
        async init(model) {
            this.reset();

            this.user = model;

            console.log(this.user);
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        cancel() {
            this.$emit("cancel");
            this.close();
        },
        close() {
            this.display = false;
        },
        wholeNumber(model) {
            let number = parseInt(model);
            return Math.ceil(number);
        },
        async submit() {
            let model = this.model;

            this.loading = true;

            try {
                let payload = {};

                let user = this.user;

                if (!user) throw new Error("Could not find a user");

                payload = {
                    userToken: this.$fluro.auth.getCurrentToken(),
                    personaId: user._id,
                    competition: model.competition._id,
                    feedback: model.feedback,
                    returnPrint: model.returnPrint,
                    complimentaryEntries: this.wholeNumber(model.complimentaryEntries),
                    complimentary: true,
                    realm: this.realm,
                };

                console.log(payload);

                let response = await this.$fluro.api
                    .post("/reaction/spark/644224e42a45f35126c31f6c", payload)
                    .then(({ data }) => data);
                console.log("RESPONSE", response);

                if (response.result) {
                    console.log("ERROR", response.result);
                    this.showError = true;
                    this.errorMessage = response.result;
                    this.loading = false;
                } else if (response && response.length) {
                    this.$emit("confirm", response);
                }
            } catch (err) {
                console.log("ERROR", err);
                this.showError = true;
                this.errorMessage = err;
                this.loading = false;
            }

            // this.loading = false;
        },
    },

    watch: {
        "model.competition._id": async function (v) {
            if (v) {
                let live = this.model.competition.realms.some((realm) => realm._id == this.$app.liveRealm);
                // let sandbox = this.model.competition.realms.some((realm) => realm._id == this.$app.sandboxRealm);

                if (live) this.realm = this.$app.liveRealm;
                // if (sandbox) this.realm = this.$app.sandboxRealm;

                console.log("REALM", this.realm);
            } else {
                this.realm = "";
            }
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.complimentary-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
