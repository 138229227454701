<template>
    <div class="admin-user full-height full-width">
        <div class="dialog-error error px-4" :class="{ active: error }">
            <div class="text d-flex align-center">
                <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                <b class="white--text">{{ errorMessage }}</b>
            </div>

            <v-btn x-small icon fab color="white" @click="error = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>

        <div class="overflow-hidden full-height full-width p-relative">
            <template v-if="loading">
                <v-container fluid class="pa-6 full-height full-width">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </v-container>
            </template>

            <template v-else>
                <div class="p-absolute top zindex-10 full-width">
                    <v-container
                        fluid
                        class="pa-6 page-header"
                        :class="{ scrolled: scrolled || $vuetify.breakpoint.xsOnly }"
                    >
                        <v-row>
                            <v-col class="d-flex align-center justify-space-between" cols="12">
                                <h2>Edit User</h2>

                                <div class="action-buttons">
                                    <v-btn
                                        class="ml-3"
                                        :class="{ grey: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="grey"
                                        small
                                        @click="close"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="dark" small>mdi-close</v-icon>
                                        </template>

                                        <template v-else>Close</template>
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ success: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="success"
                                        :disabled="!valid"
                                        :loading="saving"
                                        small
                                        @click="save"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="white" small>mdi-check</v-icon>
                                        </template>

                                        <template v-else>Save</template>
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        dark
                                        :class="{ dark: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="dark"
                                        small
                                        :loading="sendingReset"
                                        @click="sendPasswordReset()"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="white" small>mdi-lock-reset</v-icon>
                                        </template>

                                        <template v-else>Send Password Reset</template>
                                    </v-btn>

                                    <v-btn
                                        class="ml-3"
                                        :class="{ primary: $vuetify.breakpoint.xsOnly }"
                                        :icon="$vuetify.breakpoint.xsOnly"
                                        color="primary"
                                        small
                                        @click="openComplimentaryDialog()"
                                    >
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <v-icon color="white" small>mdi-currency-usd-off</v-icon>
                                        </template>

                                        <template v-else>Add Complimentary Entries</template>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>

                <div class="overflow-y-scroll full-height full-width">
                    <div class="spacer"></div>
                    <v-container class="mb-6" style="max-width: 768px">
                        <v-row dense>
                            <v-col cols="6">
                                <p class="primary--text small--text">First Name</p>
                                <v-text-field
                                    ref="email"
                                    v-model="persona.firstName"
                                    type="text"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter first name..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="6">
                                <p class="primary--text small--text">Last Name</p>
                                <v-text-field
                                    ref="email"
                                    v-model="persona.lastName"
                                    type="text"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter last name..."
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12">
                                <p class="primary--text small--text">Email Address</p>
                                <v-text-field
                                    ref="email"
                                    v-model="persona.username"
                                    type="email"
                                    class="field"
                                    :disabled="loading"
                                    dense
                                    filled
                                    solo
                                    flat
                                    placeholder="Please enter email..."
                                    required
                                    :rules="[rules.required, rules.email]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!-- <v-row dense>
                            <v-col cols="12">
                                <p class="primary--text small--text">Password</p>
                                <v-text-field ref="password" v-model="persona.password" :disabled="loading" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" @click:append="showPassword = !showPassword" class="field" dense filled solo flat prepend-inner-icon="mdi-lock-outline" label="" placeholder="Please enter your password..." required :rules="[rules.required]"> </v-text-field>
                            </v-col>
                        </v-row> -->

                        <v-row dense>
                            <!-- <v-col cols="12">
                                <v-switch class="ma-0" v-model="persona.artist" inset>
                                    <template v-slot:label>
                                        <span class="small--text">Give access to the Artist portal</span>
                                    </template>
                                </v-switch>
                            </v-col> -->

                            <v-col cols="12">
                                <v-switch class="ma-0" v-model="persona.judge" @change="updateJudge" inset>
                                    <template v-slot:label>
                                        <span class="small--text">Give access to the Judging portal</span>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row>

                        <!-- <v-row dense v-if="showCategories">
                            <v-col cols="12">
                                <autocomplete-field ref="autocomplete" :selected="categories" v-model="contact.data.categories" hide-details return-object :loading="loading"></autocomplete-field>
                            </v-col>
                        </v-row> -->

                        <v-row v-if="entries.length">
                            <v-col cols="12">
                                <p class="primary--text small--text">Complimentary</p>

                                <v-card
                                    class="pa-4 mb-4 d-flex align-center elevation-0"
                                    v-for="entry in entries"
                                    :key="entry._id"
                                >
                                    <v-img
                                        class="mr-4"
                                        style="border-radius: 4px"
                                        v-if="image(entry)"
                                        width="40"
                                        height="40"
                                        :src="$fluro.asset.imageUrl(image(entry))"
                                    ></v-img>

                                    <div class="d-flex flex-column full-width mr-4">
                                        <b class="small--text">{{ entry.title }}</b>

                                        <div class="status-cell">
                                            <span class="inline-tag" :class="entry.status">{{ entry.status }}</span>
                                        </div>
                                    </div>

                                    <v-btn
                                        color="dark"
                                        icon
                                        class="ml-auto"
                                        @click="
                                            $refs.alertDialog.open(
                                                entry,
                                                `Are you sure you want to remove <b>${entry.title}</b>? This cannot be undone.`
                                            )
                                        "
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </template>
        </div>

        <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>

        <complimentary-dialog ref="complimentaryDialog" @confirm="confirm"></complimentary-dialog>
        <alert-dialog ref="alertDialog" @confirm="confirmDelete"></alert-dialog>
    </div>
</template>

<script>
// Services
import Persona from "@/modules/app-module/persona";
import Contact from "@/modules/app-module/contact";
import Entry from "@/modules/app-module/entry";

// Components
import AutocompleteField from "@/components/form-field/autocomplete-field.vue";
import ComplimentaryDialog from "@/components/dialogs/complimentary-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";

// Packages
import _ from "lodash";

export default {
    name: "admin-user",

    meta: {
        title: "User",
    },

    components: {
        AutocompleteField,
        ComplimentaryDialog,
        AlertDialog,
    },

    data() {
        return {
            error: false,
            scrolled: false,
            errorMessage: "",
            persona: {},
            entries: [],
            contact: { data: {} },
            loading: false,
            saving: false,
            sendingReset: false,
            // showPassword: false,
            showCategories: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            categories: {
                minimum: 1,
                maximum: 0,
                options: [],
                title: "What categories will they judge?",
                placeholder: "Please select categories...",
                type: "category",
                filters: [
                    {
                        key: "status",
                        comparator: "in",
                        values: ["active"],
                    },
                ],
                select: ["title", "data"],
            },
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        orgData() {
            console.log("STORE", this.$store.getters["org/getOrgData"]);
            return this.$store.getters["org/getOrgData"];
        },
        valid() {
            let persona = this.persona;
            let contact = this.contact;

            if (!persona.firstName || !persona.firstName.length) return false;

            if (!persona.lastName || !persona.lastName.length) return false;

            if (!persona.username || !persona.username.length) return false;

            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailTest = pattern.test(persona.username);

            if (!emailTest) return false;

            // if (this.showCategories) {
            //     if (!contact.data || !contact.data.categories || !contact.data.categories.length) return false;
            // }

            return true;
        },
    },

    methods: {
        image(entry) {
            return entry?.data?.image;
        },
        updateJudge(v) {
            this.showCategories = v;
        },
        checkArtist(persona) {
            const ARTIST_ACCESS = this.$app.artistRole;
            return persona.permissionSets.some((permissionSet) =>
                permissionSet.roles.some((permission) => permission._id == ARTIST_ACCESS)
            );
        },
        checkJudge(persona) {
            const JUDGE_ACCESS = this.$app.judgeRole;
            return persona.permissionSets.some((permissionSet) =>
                permissionSet.roles.some((permission) => permission._id == JUDGE_ACCESS)
            );
        },
        checkAdmin(persona) {
            const ADMIN_ACCESS = this.$app.adminRole;
            return persona.permissionSets.some((permissionSet) =>
                permissionSet.roles.some((permission) => permission._id == ADMIN_ACCESS)
            );
        },
        async init() {
            this.loading = true;

            try {
                let persona = await Persona.get(this.$route.params.id).then(({ data }) => data);
                console.log("PERSONA", persona);

                let entries = await Entry.list().then(({ data }) => data);
                this.entries = entries.filter((entry) => {
                    let personaMatch = entry?.managedAuthor?._id == persona._id;
                    let complimentary = entry?.data?.complimentary;

                    return personaMatch && complimentary;
                });

                console.log("ENTRIES", this.entries);

                this.persona = _.cloneDeep(persona);

                this.persona.artist = this.checkArtist(persona);
                this.persona.judge = this.checkJudge(persona);
                this.persona.admin = this.checkAdmin(persona);

                if (this.persona.judge) this.showCategories = true;

                let query = {
                    _type: "contact",
                    "data.personaId": persona._id,
                };

                let contact = await this.$fluro.api.post("/content/_query", query).then(({ data }) => data);
                console.log("CONTACT", contact);

                this.contact = contact[0];
                this.contact.data = this.contact?.data || {};
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.loading = false;
        },
        close() {
            this.$router.push({ name: "admin.users" });
        },
        async save() {
            this.saving = true;

            const PHOTO_AWARDS_REALM = this.$app.liveRealm;
            const JUDGE_ACCESS = this.$app.judgeRole;
            const ARTIST_ACCESS = this.$app.artistRole;
            const ADMIN_ACCESS = this.$app.adminRole;

            let user = this.$app.user;

            try {
                let persona = this.persona;
                let contact = this.contact;

                let contactPayload = {
                    firstName: persona.firstName,
                    lastName: persona.lastName,
                    emails: [persona.username],
                    data: contact?.data || {},
                };

                if (persona.judge) {
                    let categories = contact.data.categories || [];
                    contactPayload.data.categories = categories.map((item) => item?.item?._id || item);
                }

                let updatedContact = await Contact.update(this.contact._id, contactPayload).then(({ data }) => data);
                console.log("UPDATED CONTACT", updatedContact);

                let personaPayload = {
                    firstName: persona.firstName,
                    lastName: persona.lastName,
                    username: persona.username,
                    permissionSets: [],
                };

                // if (persona.password && persona.password.length) {
                //     personaPayload.password = persona.password;
                // }

                if (persona.artist) {
                    personaPayload.permissionSets.push({
                        realms: [PHOTO_AWARDS_REALM],
                        roles: [ARTIST_ACCESS],
                    });
                }

                if (persona.judge) {
                    personaPayload.permissionSets.push({
                        realms: [PHOTO_AWARDS_REALM],
                        roles: [JUDGE_ACCESS],
                    });
                }

                if (persona.admin) {
                    personaPayload.permissionSets.push({
                        realms: [PHOTO_AWARDS_REALM],
                        roles: [ADMIN_ACCESS],
                    });
                }

                let updatedPersona = await Persona.update(persona._id, personaPayload).then(({ data }) => data);
                console.log("UPDATED PERSONA", updatedPersona);

                if (user && user.persona && user.persona === persona._id) {
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    this.$fluro.api.get("/session/refresh", { cache: false });
                    await new Promise((res) => setTimeout(res, 100));
                    await this.$fluro.api.get("/session/refresh", { cache: false });

                    let session = await this.$fluro.auth.sync();

                    console.log("SESSION", session);

                    await this.$app.init(session);
                }

                await this.init();
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.saving = false;
        },
        openComplimentaryDialog() {
            this.$refs.complimentaryDialog.open(this.persona);
        },
        async confirm() {
            await this.$fluro.resetCache();
            await this.init();
            this.$refs.complimentaryDialog.close();
        },
        async confirmDelete(model) {
            try {
                let deleted = await Entry.delete(model._id).then(({ data }) => data);
                console.log("DELETED", deleted);

                await this.init();

                this.$refs.alertDialog.close();
            } catch (err) {
                console.log("ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }
        },
        async sendPasswordReset() {
            this.sendingReset = true;

            let persona = this.persona;

            try {
                const url = `https://${this.orgData.data.links.awards}/fluro/application/forgot`;
                const email = persona.username;
                const payload = {
                    username: email,
                    redirect: "/auth/reset",
                    emial: email,
                };
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                };
                const reset = await fetch(url, options);

                const status = reset.status;
                if (status == 200) {
                    this.loading = false;
                } else {
                    this.loading = false;
                }

                // let reset = await this.$fluro.auth.sendResetPasswordRequest(
                //     {
                //         username: persona.username,
                //         redirect: "/auth/reset",
                //         mail: {
                //             from: `${this.orgData.title} <hello@australianphotographicprize.com.au>`,
                //             subject: "Reset your password",
                //             text:
                //                 "<p>Hi " +
                //                 persona.firstName +
                //                 `,</p><p>Please click the link below to reset your password. If you did not request this, please ignore this email or get in contact with ${this.orgData.title}.</p>`,
                //         },
                //     },
                //     {
                //         application: true,
                //     }
                // );
                if (reset?.status == 200) {
                    this.snackbar = true;
                    this.snackbarText = "Password reset successfully sent";
                    this.snackbarColor = "success";
                } else {
                    this.snackbar = true;
                    this.snackbarText = "Password reset failed to send";
                    this.snackbarColor = "error";
                }
            } catch (err) {
                console.log("RESET ERROR", err);
                this.error = true;
                this.errorMessage = err.response.data.message;
            }

            this.sendingReset = false;
        },
        handleScroll(e) {
            let scrollTop = e.target.scrollTop;

            if (scrollTop > 30) {
                this.scrolled = true;
            } else {
                this.scrolled = false;
            }
        },
    },

    async mounted() {
        await this.init();

        this.$nextTick(() => {
            const scrollContainer = document.querySelector(".overflow-y-scroll");
            scrollContainer.addEventListener("scroll", this.handleScroll);
        });
    },

    destroyed() {
        const scrollContainer = document.querySelector(".overflow-y-scroll");
        if (scrollContainer) scrollContainer.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style lang="scss"></style>
